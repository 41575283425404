import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import bootstrapVue from "bootstrap-vue/dist/bootstrap-vue.esm";
import "bootstrap/dist/css/bootstrap.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter , faInstagram , faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueParticles from 'vue-particles';
import vueScrollto from 'vue-scrollto'
import 'animate.css';

Vue.use(vueScrollto)

Vue.use(VueParticles);

library.add(fas);
library.add(faFacebook);
library.add(faTwitter);
library.add(faInstagram);
library.add(faLinkedin);
Vue.component("font-awesome-icon", FontAwesomeIcon);


Vue.use(bootstrapVue);
Vue.use(vueScrollto, {
  container: "body",
  duration: 5000,

  offset: 0,
  force: false,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
