<template>
  <div id="app">
    
    <Home/>
  </div>
</template>


<script>
//import Nav from "./components/navbar.vue";
import Home from "./components/home.vue";
export default{
  components:{
  //  Nav,
    Home
  }
  
}
</script>

