<template>
    <div id="home_page" class="container_fluid">

        <div id="homePage_main_container" class="container_fluid">
            <div class="over-lay"></div>
            <div id="nav_bar" :class="{'fixed-pos' : isScroll , 'slide-up-nav' : isScrollz}">
                <div class="container_fluid">
                    <div class="container nav-bar">
                        <div class="row">
                            <div class="logo-section col-8 col-lg-6">
                                <img src="../assets/sidelogo.png" alt="" srcset="">
                            </div>
                            <div class="nav-menu col-6 d-lg-block d-none">
                                <ul>
                                    <li v-scroll-to="{el:'#homePage_main_container'}">home <span></span></li>
                                    <li v-scroll-to="{el:'#about_us' , duration:500}">about <span></span></li>
                                    <li v-scroll-to="{el:'#featured' , duration:500}" >features <span></span></li>
                                    <li v-scroll-to="{el:'#download' , duration:500}">download <span></span></li>
                                    <button></button>
                                </ul>
                            </div>
                            <div class="col-4 d-lg-none d-block ">

                                <div class="menu_button menu-btn" @click="open = !open" :class="{'clicked-menu-btn' : open}">
                                    <span class="top"></span>
                                    <span class="middel"></span>
                                    <span class="bottom"></span>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div class="container-fluid sub_menu" :class="{'open':open}">
                    <div class="nav-menu">
                        <ul>
                            <li v-scroll-to="{el:'#homePage_main_container'}">home <span></span></li>
                            <li v-scroll-to="{el:'#about_us' , duration:1500}">about <span></span></li>
                            <li v-scroll-to="{el:'#featured' , duration:1500}" >features <span></span></li>
                            <li v-scroll-to="{el:'#download' , duration:1500}">download <span></span></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container home-container" :class="{'handle' : isScroll == true}" >
                <div class="home-content col-md-6 col-12 animate__slideInLeft">
                    <span>WITH</span>
                    <h2>CTRLX</h2>
                    <h3>mobile APP</h3>
                    <div class="details-container col-lg-6 col-12">
                        <p>
                            CTRLX works to present you affordable and easy
                            install smart home solutions
                        </p>
                    </div>
                    <button v-scroll-to="{el:'#about_us' , duration:1500}">read more</button>
                    <button class="download">try for free</button>
                </div>
                <div class="home-image col-6">
                    <img src="../assets/switch.png" alt="" srcset="">
                </div>
            </div>
        </div>
        <div id="featured" class="featuer_container container_fluid">
            <div class="feature-title container">
                <div class="title">
                    <span class="tit-span">Our awesome services</span>
                    <h1>We can give the <span>best facilites</span> for you!</h1>
                </div>
            </div>
            <div id="#feature" class="feature_body container">
                <div class="feature col-lg-4 col-12">
                    <div class="body">

                        <div class="title">
                            <h4>easy to install</h4>
                        </div>
                        <div class="description">
                            <p>Everything you need is only
                            CTRLX switches themselves, no
                            need to any additional wires or
                            devices
                            </p>
                        </div>
                    </div>
                </div>
                <div class="feature col-lg-4 col-12">
                    <div class="body">

                        <div class="title">
                            <h4>Functional</h4>
                        </div>
                        <div class="description">
                            <p>you have poor internet or no
                                internet at all, don’t worry you still
                                have full control of your lights.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="feature col-lg-4 col-12">

                    <div class="body">

                        <div class="title">
                            <h4>Affordable</h4>
                        </div>
                        <div class="description">
                            <p>The switch costs less than
                                20% of the traditional smart
                                home system price
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div id="about_us" class="about-us-container container-fluid">
            <div class="about-container container">

                <div class="about-left col-lg-6 col-12">
                    <img src=".././assets/smart-home.png" alt="" srcset="">
                </div>
                <div class="about-right col-lg-6 col-12">
                    <span class="tit-span">about our company</span>
                    <h1>Our features will help to <br><span>improve busines</span></h1>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit, quaerat dolorum. Ipsum, architecto consequuntur! Odit, repellat! Velit odit consequatur, natus possimus voluptate maxime ex fugiat accusantium repudiandae beatae quam sit.
                    </p>
                    <button>start free trial</button>
                </div>

            </div>

        </div>
        <div id="screen_shots" class="main-app-features-container container-fluid">
            <div class="main-features-container container">
                <div class="title row">
                    <h1><span>main</span> features</h1>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Laboriosam saepe temporibus officia porro voluptas quis cupiditate! Magnam labore dolorum commodi vel modi, unde cum exercitationem, animi possimus repudiandae quasi quis?</p>
                </div>
                <div class="app-features row">
                    <div class="first-features features col-lg-4">
                        <div class="feature">
                            <span></span>
                            <div class="title">

                                <div class="icon">
                                    <div class="circ"></div>
                                    <img src="../assets/rocket.png" alt="" srcset="">
                                </div>

                            </div>
                            <div class="desc">
                                <h4>Switching On/Off</h4>
                                <p>your appliances and light switches from your smartphone, either while you are at home
                                    or away</p>
                            </div>
                        </div>
                        <div class="feature">
                            <span></span>
                            <div class="title">
                                <div class="icon">
                                    <div class="circ"></div>
                                    <img src="../assets/rocket.png" alt="" srcset="">
                                </div>

                            </div>
                            <div class="desc">
                                <h4>Voice commands</h4>
                                <p>ask CTRLX to turn off your home, and the system will automatically close all the lights
                                    and devices.</p>
                            </div>
                        </div>
                        <div class="feature">
                            <span></span>
                            <div class="title">
                                <div class="icon">
                                    <div class="circ"></div>
                                    <img src="../assets/rocket.png" alt="" srcset="">
                                </div>

                            </div>
                            <div class="desc">
                                <h4>Scheduling</h4>
                                <p>Schedule your lighting timing and leave your device does itself automatically.</p>
                            </div>
                        </div>
                    </div>
                    <div class="app-pic col-lg-4">
                        <img src="../assets/iphone-13.png" alt="" srcset="">
                    </div>
                    <div class="secound-features features col-lg-4">
                        <div class="feature">
                            <span></span>
                            <div class="title">

                                <div class="icon">
                                    <div class="circ"></div>
                                    <img src="../assets/rocket.png" alt="" srcset="">
                                </div>

                            </div>
                            <div class="desc">
                                <h4>Switching On/Off</h4>
                                <p>your appliances and light switches from your smartphone, either while you are at home
                                    or away</p>
                            </div>
                        </div>
                        <div class="feature">
                            <span></span>
                            <div class="title">
                                <div class="icon">
                                    <div class="circ"></div>
                                    <img src="../assets/rocket.png" alt="" srcset="">
                                </div>

                            </div>
                            <div class="desc">
                                <h4>Voice commands</h4>
                                <p>ask CTRLX to turn off your home, and the system will automatically close all the lights
                                    and devices.</p>
                            </div>
                        </div>
                        <div class="feature">
                            <span></span>
                            <div class="title">
                                <div class="icon">
                                    <div class="circ"></div>
                                    <img src="../assets/rocket.png" alt="" srcset="">
                                </div>

                            </div>
                            <div class="desc">
                                <h4>Scheduling</h4>
                                <p>Schedule your lighting timing and leave your device does itself automatically.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="download" class="contact-form-container container-fluid">
            <div class="over-lay"></div>
            <div class="contact-container">
                <div class="contact-form row">
                    <div class="over-lay"></div>
                    <div class="download-options col-md-6 col-12">
                        <h1>download our smart app</h1>
                        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorum, natus repellendus id rem velit nostrum ullam aliquam consectetur neque tempore, quam earum ab accusamus quasi dolore consequatur dolor. Eius, amet!</p>
                        <button><img src="../assets/google-play.png"></button>
                        <button><img src="../assets/apple.png" ></button>
                    </div>
                    <div class="app-mocup col-md-6 col-12">

                    </div>
                </div>
            </div>
        </div>
        <div class="copy-right">
            <h2>
                2020 © All rights reserved by
                <span>Nevada Software Solution</span>
            </h2>
        </div>
    </div>
</template>
<style lang="scss">
@import "../style/components/nav.scss";
@import "../style/components/home.scss";
</style>
<script>
export default {
    data(){
        return {
            open:false,
            isScroll:false,
            isScrollz:false
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll () {
         if(window.scrollY > 0 && window.scrollY < 400){
             this.isScrollz = true;
         }
         else if(window.scrollY > 200){
             this.isScroll = true;
             this.isScrollz = false;
         }
         else{
             this.isScroll = false;
             this.isScrollz = false;
         }
        }
    }
}
</script>
